import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue, onConcatenate } from 'redux-recompose';

import { onSetStore } from '~redux/Orders/effects';

import { actions } from './actions';
import {
  CURRENT_ORDER_TARGET,
  STORE_TARGET,
  PAST_ORDERS_TARGET,
  ONLINE_PAYMENT_TARGET,
  ORDER_POLL_TARGET,
  ORDER_POLL_STATUS_TARGET,
  INGENICO_URL_TARGET,
  ACTIVE_ORDERS_TARGET,
  STORES_TARGET,
  EXTERNAL_ERROR_ORDER_TARGET,
  INGENICO_3DS_HTML_TARGET,
  CHECKOUT_BUTTON_STATUS_TARGET,
  AWIN_KEY_TARGET
} from './constants';

const initialStateDescription = {
  [CURRENT_ORDER_TARGET]: {},
  [ACTIVE_ORDERS_TARGET]: null,
  [STORE_TARGET]: {},
  [PAST_ORDERS_TARGET]: {},
  [ONLINE_PAYMENT_TARGET]: null,
  [ORDER_POLL_TARGET]: null,
  [ORDER_POLL_STATUS_TARGET]: false,
  [INGENICO_URL_TARGET]: null,
  [INGENICO_3DS_HTML_TARGET]: null,
  [EXTERNAL_ERROR_ORDER_TARGET]: null,
  [CHECKOUT_BUTTON_STATUS_TARGET]: false,
  [AWIN_KEY_TARGET]: null,
  [STORES_TARGET]: []
};

const initialState = completeState(initialStateDescription);

const reducerDescription = {
  primaryActions: [
    actions.GET_CURRENT_ORDER,
    actions.GET_ACTIVE_ORDERS,
    actions.GET_PAST_ORDERS,
    actions.GET_STORE,
    actions.ONLINE_PAYMENT,
    actions.GET_ORDER_POLL,
    actions.GET_STORE_FOR_ACTIVE_ORDERS,
    actions.CONCATENATE_STORES
  ],
  override: {
    [actions.SET_INGENICO_URL]: onReadValue(),
    [actions.SET_FPAY_URL]: onReadValue(),
    [actions.SET_APPLEPAY_URL]: onReadValue(),
    [actions.SET_DIRECT_CLICK_TO_PAY_URL]: onReadValue(),
    [actions.BIZUM_URL_TARGET]: onReadValue(),
    [actions.SET_INGENICO_3DS_HTML]: onReadValue(),
    [actions.SET_VALUE]: onReadValue(),
    [actions.CLEAR_ALL_ORDER_DATA]: state => state.merge(initialState),
    [actions.GET_PAST_ORDERS_SUCCESS]: (state, action) => {
      const page =
        state[PAST_ORDERS_TARGET].page && action.payload.currentPage !== 1
          ? [...state[PAST_ORDERS_TARGET].page, ...action.payload.page]
          : action.payload.page;

      return state.merge({
        [`${PAST_ORDERS_TARGET}Loading`]: false,
        [PAST_ORDERS_TARGET]: {
          ...action.payload,
          page
        }
      });
    },
    [actions.CONCATENATE_STORES]: onConcatenate(),
    [actions.SET_STORE]: onSetStore
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
