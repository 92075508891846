import { CARD_PAYMENT_METHODS_ONLINE, PAYMENT_METHODS } from '~constants/paymentMethods';
import { actionCreators as orderActions } from '~redux/Orders/actions';
import { isEurope } from '~utils/regions';

import { actionCreators as homeActions } from './actions';

export const ingenicoPaymentAction = ({ response, order }) =>
  order.shoppingCart?.total === 0
    ? homeActions.createCashOrder(response, order)
    : orderActions.ingenicoStartPayment({
        orderId: response.data.id,
        createCard: order.createCard,
        ...(order.cardId && { cardId: order.cardId, ingenico3DSv2: order.ingenico3DSv2 })
      });

export const paymentsActions = ({ response, order }, privateActions) => ({
  [CARD_PAYMENT_METHODS_ONLINE.ingenico]: () => ingenicoPaymentAction({ response, order }),
  [PAYMENT_METHODS.ingenico]: () => ingenicoPaymentAction({ response, order }),
  [PAYMENT_METHODS.webpay]: () => orderActions.webPayStartPayment(response.data.id),
  [PAYMENT_METHODS.powertranz]: () =>
    orderActions.powertranzStartPayment({
      orderId: response.data.id,
      cardId: order.cardId,
      cardCode: order.cardCode,
      expiration: order.expiration
    }),
  [PAYMENT_METHODS.mercantil]: () =>
    orderActions.mercantilStartPayment({
      orderId: response.data.id,
      cardId: order.cardId,
      cardCode: order.cardCode,
      cardToken: order.cardToken
    }),
  [PAYMENT_METHODS.mercantilGuest]: () =>
    orderActions.mercantilStartPayment({
      orderId: response.data.id,
      cardNumber: order.cardNumber,
      cardCode: order.cardCode,
      cardToken: order.cardToken
    }),
  [PAYMENT_METHODS.powertranzGuest]: () =>
    orderActions.powertranzStartPayment({
      orderId: response.data.id,
      cardNumber: order.cardNumber,
      cardCode: order.cardCode,
      expiration: order.expiration
    }),
  [PAYMENT_METHODS.oneclick]: () =>
    orderActions.oneclickStartPayment({
      orderId: response.data.id,
      cardId: order.cardId,
      orderSuccessCallback: privateActions.createOrderSuccess
    }),
  [PAYMENT_METHODS.fpay]: () => orderActions.fpayStartPayment(response.data.id),
  [PAYMENT_METHODS.applepay]: () => orderActions.applePayStartPayment(response.data.id),
  [PAYMENT_METHODS.directClickToPay]: () => orderActions.directClickToPayStartPayment(response.data.id),
  [PAYMENT_METHODS.bizum]: () => orderActions.bizumStartPayment(response.data.id),
  [PAYMENT_METHODS.tenpo]: () => orderActions.brickStartPayment(response.data),
  [PAYMENT_METHODS.mercadopago]: () => orderActions.brickStartPayment(response.data),
  // IF IN EU SODEXO PROCESS THE PAYMENT AS INGENICO
  [PAYMENT_METHODS.sodexo]: () =>
    isEurope()
      ? ingenicoPaymentAction({ response, order })
      : orderActions.sodexoStartPayment(response.data.id),
  [PAYMENT_METHODS.paypal]: () => orderActions.paypalStartPayment(response.data.id),
  // EDENRED PROCESS THE PAYMENT AS INGENICO
  [PAYMENT_METHODS.edenred]: () => ingenicoPaymentAction({ response, order }),
  [PAYMENT_METHODS.sinpeMovil]: () =>
    orderActions.sinpeMovilStartPayment({
      orderId: response.data.id,
      clientDocumentId: order.clientDocumentId
    })
});
