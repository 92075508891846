/* eslint-disable camelcase */
import i18next from 'i18next';

i18next.addResources('es_CL', 'CheckoutPaymentMethodList', {
  title: 'Métodos de pago',
  card: 'Tarjeta en tienda',
  delivery_card: 'Tarjeta al recibir',
  cash: 'Efectivo',
  ingenico: 'Tarjeta',
  webpay: 'Webpay',
  sodexo: 'Sodexo',
  fpay: 'Fpay',
  applepay: 'Apple Pay',
  directClickToPay: 'Click to Pay',
  edenred: 'Ticket Restaurant / Edenred',
  mercado_pago: 'Débito y crédito',
  bizum: 'Bizum',
  powertranz_payment: 'tarjeta de crédito',
  powertranzGuest: 'Pago Online',
  mercantilGuest: 'Pago Online',
  oneclick_payment: 'tarjeta de crédito',
  oneclick: 'tarjeta de crédito',
  ingenico_payment: 'Online',
  webpay_payment: 'Webpay',
  myCards: 'Tus tarjetas inscritas',
  addCard: 'Agregar tarjeta',
  guestAddCard: 'Ingresar tarjeta',
  useCard: 'Usar esta tarjeta',
  cancel: 'cancelar',
  newCard: 'Nueva tarjeta',
  numberCard: 'Número de la tarjeta',
  expiryDate: 'Vencimiento',
  cardCode: 'CVC',
  numberCardPlaceholder: '1234 1234 1234 1234',
  expiryDatePlaceholder: 'mm/yy',
  cardCodePlaceholder: '123',
  invalidCard: 'El número de tarjeta de credito utilizado es inválido',
  invalidMonth: 'El mes de vencimiento es incorrecto',
  expireDate: 'La fecha de vencimiento es incorrecta',
  addCardRedirectionMessage: 'Se va a redirigir a la página de inscripción de tarjetas de crédito',
  addCardErrorCancelled: 'Se ha cancelado la inscripción de la tarjeta de crédito',
  addCardError: 'Ha ocurrido un error inscribiendo la tarjeta de crédito',
  addCardSuccess: 'Se ha inscrito la tarjeta de crédito',
  cashDesactivated: '* Por motivos de seguridad hemos desactivado el pago en efectivo.',
  extendedTimeDelivery: '* El tiempo de entrega puede verse incrementado por la situación actual.',
  emergencyMessage: 'Recuerda que siempre puedes pagar con tarjeta en tu casa',
  paypal: 'PayPal',
  acceptToCreateCard: 'Guardar tarjeta para futuras compras',
  deletedCardSuccessfully: 'Se ha eliminado correctamente tu tarjeta',
  deletedCardError: 'No pudimos eliminar tu tarjeta. Intenta nuevamente.',
  available: 'Disponibles',
  mustChooseMethod: 'Debes seleccionar un método de pago',
  noCash:
    // eslint-disable-next-line no-template-curly-in-string
    '*El pago en efectivo no se encuentra habilitado actualmente en esta tienda para compras mayores a ${{max}}.',
  cardDataWillBeAsked: '* Los datos de la tarjeta se solicitarán tras confirmar este pedido'
});

i18next.addResources('es_ES', 'CheckoutPaymentMethodList', {
  title: 'Método de pago',
  card: 'Datáfono en tienda',
  delivery_card: 'Datáfono a domicilio',
  cash: 'Efectivo',
  ingenico: 'Tarjeta',
  ingenico_payment: 'Tarjeta',
  info3DS:
    'Para pago con tarjeta sólo aceptamos Visa y MasterCard. Por tu seguridad, trabajamos con doble autentificación, por lo que antes del cobro te llegará un SMS de tu banco con un código de autorización. Es importante que esta opción la tengas activada con tu entidad.',
  addCard: 'Inscribir tarjeta con el pago',
  ingenico_oneclick_payment: 'Tarjeta',
  cashDesactivated: '* Por motivos de seguridad sólo está disponible pago con tarjeta.',
  emergencyMessage: 'Datáfono: El pago con tarjeta se realizará en el momento de la entrega',
  warningEdenred: 'Método de pago solo válido para tarjeta',
  acceptToCreateCard: 'Guardar tarjeta para futuras compras',
  directClickToPay: 'Click to Pay'
});

i18next.addResources('pt_PT', 'CheckoutPaymentMethodList', {
  title: 'Forma de pagamento',
  card: 'Terminal MB em loja',
  delivery_card: 'Terminal MB ao domicílio',
  cash: 'Dinheiro',
  ingenico: 'Pag. Online',
  powertranz: 'Cartão',
  ingenico_payment: 'Cartão',
  add_method: 'Adicionar forma de pagamento',
  newCard: 'Novo cartão',
  info3DS:
    'Só são aceites pagamentos com cartão da Rede Visa ou MasterCard. Para vossa segurança, trabalhamos com dupla autenticação, pelo que receberás uma SMS da tua entidade bancária com código de autorização antes de cada transacção. É importante que tenha esta opção activa com a entidade emissora do cartão.',
  addCard: 'Registrar cartão com pagamento',
  numberCard: 'Número do cartão',
  expiryDate: 'Vencimiento',
  invalidCard: 'O número do cartão de crédito usado é inválido',
  invalidMonth: 'O mês de vencimento está incorreto',
  expireDate: 'A data de validade está incorreta',
  ingenico_oneclick_payment: 'Cartão',
  myCards: 'Seus cartões registrados',
  cashDesactivated: '* Por motivos de segurança apenas está disponível pagamento Online.',
  extendedTimeDelivery: '* O tempo de entrega poderá ver-se incrementado pela situação actual.',
  creditCardNotAllowed:
    'Só são aceites pagamentos com cartão da Rede Visa ou MasterCard. Para vossa segurança, trabalhamos com dupla autenticação, pelo que receberás uma SMS da tua entidade bancária com código de autorização antes de cada transacção. É importante que tenha esta opção activa com a entidade emissora do cartão.',
  acceptToCreateCard: 'Guardar para futuras compras',
  mustChooseMethod: 'Você deve selecionar um método de pagamento',
  cardDataWillBeAsked: '* Os dados do cartão serão solicitados após a confirmação desta encomenda'
});

i18next.addResources('es_PA', 'CheckoutPaymentMethodList', {
  myCards: 'Pago Online',
  card: 'Punto de venta al retirar',
  delivery_card: 'Punto de venta en entrega',
  creditCardNotAllowed: 'Aceptamos tarjetas de crédito o débito Visa, Mastercard o American Express',
  creditCardNotAllowedMercantil: 'Aceptamos tarjetas de crédito o débito Visa o Mastercard',
  powertranzUpdateCardsDeleted:
    'Actualizamos el sistema y como parte de este proceso hemos eliminado las tarjetas inscritas. Puedes volver a registrar tus tarjetas utilizando la opción "Agregar tarjeta"'
});

i18next.addResources('es_CR', 'CheckoutPaymentMethodList', {
  powertranz: 'Pag. Online',
  card: 'Punto de venta al retirar',
  delivery_card: 'Punto de venta en entrega',
  myCards: 'Pago Online',
  creditCardNotAllowed: 'Aceptamos tarjetas de crédito o débito Visa, Mastercard o American Express',
  powertranzUpdateCardsDeleted:
    'Actualizamos el sistema y como parte de este proceso hemos eliminado las tarjetas inscritas. Puedes volver a registrar tus tarjetas utilizando la opción "Agregar tarjeta"'
});

i18next.addResources('es_GT', 'CheckoutPaymentMethodList', {
  cash: 'Efectivo',
  card: 'POS al retirar',
  delivery_card: 'POS en entrega',
  online: 'Online',
  creditCardNotAllowed: 'Aceptamos tarjetas de crédito o débito Visa, Mastercard o American Express',
  powertranzUpdateCardsDeleted:
    'Actualizamos el sistema y como parte de este proceso hemos eliminado las tarjetas inscritas. Puedes volver a registrar tus tarjetas utilizando la opción "Agregar tarjeta"'
});
