import { t } from 'i18next';

export const CURRENT_ORDER_TARGET = 'currentOrder';

export const ACTIVE_ORDERS_TARGET = 'activeOrders';

export const PAST_ORDERS_TARGET = 'pastOrders';

export const STORE_TARGET = 'store';

export const ONLINE_PAYMENT_TARGET = 'onlinePayment';

export const ORDER_POLL_TARGET = 'orderPoll';

export const ERROR_UNPROCESSABLE_ITEMS = 'UNPROCESSABLE_ITEMS';

export const ORDER_POLL_STATUS_TARGET = 'orderPollFilled';

export const TYPE_DELIVERY = 'pj_delivery';

export const TYPE_IN_STORE = 'in_store';

export const INGENICO_URL_TARGET = 'ingenicoURL';

export const INGENICO_3DS_HTML_TARGET = 'ingenico3dsHtml';

export const STORES_TARGET = 'stores';

export const EXTERNAL_ERROR_ORDER_TARGET = 'externalErrorOrder';

export const AWIN_KEY_TARGET = 'awinKey';

export const FPAY_URL_TARGET = 'fpayURL';

export const APPLEPAY_URL_TARGET = 'applepayURL';

export const DIRECT_CLICK_TO_PAY_URL_TARGET = 'directClickToPayURL';

export const BIZUM_URL_TARGET = 'bizumURL';

export const ORDER_STATUSES = {
  CREATED: 'created',
  APPROVED: 'approved',
  COOKING: 'cooking',
  MAKING: 'prepared',
  PICKUP: 'fulfilled',
  DELIVERY: 'in_transit',
  FAILED: 'failed',
  CLOSED: 'closed',
  KITCHEN_DELAY: 'kitchen_delay',
  IN_DELAY: 'in_delay',
  PENDING_ONLINE_PAYMENT: 'pending_online_payment',
  FOR_LATER: 'for_later',
  PAYMENT_FAILED: 'payment_failed',
  READY: 'ready',
  CREATION_SCHEDULED: 'creation_scheduled',
  CANCELED: 'canceled',
  FULFILLED: 'fulfilled'
};

export const PAYMENT_STATUS = {
  CREATED: 'created',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  REVERSED: 'reversed',
  UNKNOWN: 'unknown',
  SECURE_VALIDATION: 'secureValidation'
};

export const STATUS = {
  [ORDER_STATUSES.PENDING_ONLINE_PAYMENT]: {
    message: t('Order:orderPending'),
    lottieIcon: 'receive',
    order: 1,
    lineGreenPercent: 0
  },
  [ORDER_STATUSES.PAYMENT_FAILED]: {
    message: t('Order:paymentFailed'),
    lottieIcon: 'receive',
    order: 1,
    lineGreenPercent: 0
  },
  [ORDER_STATUSES.FOR_LATER]: {
    message: t('Order:orderLater'),
    lottieIcon: 'receive',
    order: 1,
    lineGreenPercent: 0
  },
  [ORDER_STATUSES.CREATED]: {
    message: t('Order:orderReceive'),
    lottieIcon: 'receive',
    order: 1,
    lineGreenPercent: 0
  },
  [ORDER_STATUSES.APPROVED]: {
    message: t('Order:orderReceive'),
    lottieIcon: 'receive',
    order: 1,
    lineGreenPercent: 0
  },
  [ORDER_STATUSES.COOKING]: {
    message: t('Order:orderPrepared'),
    lottieIcon: 'prepared',
    order: 2,
    lineGreenPercent: 0.35
  },
  [ORDER_STATUSES.KITCHEN_DELAY]: {
    message: t('Order:orderPrepared'),
    lottieIcon: 'prepared',
    order: 2,
    lineGreenPercent: 0.35
  },
  [ORDER_STATUSES.MAKING]: {
    message: t('Order:orderOven'),
    lottieIcon: 'oven',
    order: 3,
    lineGreenPercent: 0.65
  },
  [ORDER_STATUSES.DELIVERY]: {
    message: t('Order:orderDelivery'),
    lottieIcon: 'delivery',
    order: 4,
    lineGreenPercent: 1
  },
  [ORDER_STATUSES.PICKUP]: {
    message: t('Order:orderPickup'),
    lottieIcon: 'pickup',
    order: 5,
    lineGreenPercent: 1
  },
  [ORDER_STATUSES.READY]: {
    message: t('Order:orderPickup'),
    lottieIcon: 'pickup',
    order: 5,
    lineGreenPercent: 1
  },
  [ORDER_STATUSES.CREATION_SCHEDULED]: {
    message: t('Order:orderScheduled'),
    lottieIcon: 'programed',
    order: 1,
    lineGreenPercent: 0,
    width: 60,
    height: 60
  }
};

export const TIME_REQUEST = 60000;
export const INTERVAL_REQUEST = 1000;
export const CLEARCART_DELAY = 3000;
export const CHECKOUT_BUTTON_STATUS_TARGET = 'checkoutButtonStatus';
