/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable prettier/prettier */
/* eslint-disable id-length */
import cashIcon from '~assets/paymentsMethod/cash_icon.svg';
import webPayIcon from '~assets/paymentsMethod/webpay_icon.svg';
import sodexoIcon from '~assets/paymentsMethod/sodexo_icon.svg';
import cardIcon from '~assets/paymentsMethod/card_icon.svg';
import ingenicoIcon from '~assets/paymentsMethod/ingenico.svg';
import visaIcon from '~assets/paymentsMethod/visa.svg';
import discoveryIcon from '~assets/paymentsMethod/discovery.svg';
import masterCardIcon from '~assets/paymentsMethod/mastercard.svg';
import americanExpressIcon from '~assets/paymentsMethod/american-express.svg';
import redcompraIcon from '~assets/paymentsMethod/redcompra-web.svg';
import fpayIcon from '~assets/paymentsMethod/fpay.svg';
import paypalIcon from '~assets/paymentsMethod/paypal.svg';
import applepayIcon from '~assets/paymentsMethod/applepay.svg';
import directClickToPayIcon from '~assets/paymentsMethod/directClickToPay.svg';
import tenpoIcon from '~assets/paymentsMethod/tenpo.svg';
import edenredIcon from '~assets/paymentsMethod/edenred.svg';
import mercadopagoIcon from '~assets/paymentsMethod/mercadopago.svg';
import bizumIcon from '~assets/paymentsMethod/bizum.svg';
import sinpeMovil from '~assets/paymentsMethod/sinpeMovil.svg';

export const CARD_PAYMENT_METHODS_ONLINE = {
  powertranz: 'webOnline',
  oneclick: 'oneclick',
  ingenico: 'ingenicoCards'
};

export const PAYMENT_METHODS = {
  cash: 'cash',
  card: 'card',
  online: 'online',
  ingenico: 'ingenico',
  webpay: 'web_online',
  powertranz: 'powertranz',
  powertranzGuest: 'powertranzGuest',
  oneclick: 'oneclick',
  sodexo: 'sodexo',
  fpay: 'fpay',
  applepay: 'applePay',
  directClickToPay: 'directClickToPay',
  paypal: 'paypal',
  tenpo: 'tenpo',
  edenred: 'edenred',
  mercantil: 'mercantil',
  mercantilGuest: 'mercantilGuest',
  bizum: 'bizum',
  mercadopago: 'mercado_pago',
  webOnline: 'web_online',
  sinpeMovil: 'sinpeMovil'
};

export const PAYMENT_METHODS_ONLINE = {
  online: 'online',
  ingenico: 'webOnline',
  webpay: 'webpay',
  powertranz: 'webOnline',
  oneclick: 'oneclick',
  ingenicoCards: 'webOnline',
  sodexo: 'sodexo',
  fpay: 'fpay',
  applepay: 'applePay',
  directClickToPay: 'directClickToPay',
  paypal: 'paypal',
  tenpo: 'tenpo',
  'web_online': 'web_online',
  bizum: 'bizum',
  mercado_pago: 'mercado_pago',
  mercantil: 'mercantil',
  sinpeMovil: 'sinpeMovil'
};

export const PAYMENT_METHODS_SERVER = {
  cash: 'cash',
  credit: 'credit',
  webOnline: 'webOnline',
  ingenico: 'ingenico',
  powertranz: 'powertranz',
  sodexo: 'sodexo',
  fpay: 'fpay',
  applepay: 'applePay',
  directClickToPay: 'directClickToPay',
  paypal: 'paypal',
  tenpo: 'tenpo',
  edenred: 'edenred',
  bizum: 'bizum',
  mercadopago: 'mercadoPago',
  'web_online': 'web_online',
  mercantil: 'mercantil',
  sinpeMovil: 'sinpeMovil'
};

const paymentCash = {
  [PAYMENT_METHODS_SERVER.cash]: {
    name: PAYMENT_METHODS.cash,
    isOnline: false,
    icon: cashIcon,
    hide: false
  }
};

const paymentCard = {
  [PAYMENT_METHODS_SERVER.credit]: {
    name: PAYMENT_METHODS.card,
    isOnline: false,
    icon: cardIcon,
    hide: false
  }
};

const paymentIngenico = {
  [PAYMENT_METHODS_SERVER.webOnline]: {
    name: PAYMENT_METHODS.ingenico,
    isOnline: true,
    icon: ingenicoIcon,
    hide: false
  }
};

const paymentMercantil = {
  [PAYMENT_METHODS_SERVER.webOnline]: {
    name: PAYMENT_METHODS.mercantil,
    isOnline: true,
    hide: true,
    icon: cardIcon
  }
};

const paymentMercantilGuest = {
  [PAYMENT_METHODS_SERVER.webOnline]: {
    name: PAYMENT_METHODS.mercantilGuest,
    isOnline: true,
    hide: false,
    icon: cardIcon
  }
};

const paymentPowertranz = {
  [PAYMENT_METHODS_SERVER.webOnline]: {
    name: PAYMENT_METHODS.powertranz,
    originalName: PAYMENT_METHODS.webOnline,
    isOnline: true,
    hide: true,
    icon: cardIcon
  }
};

const paymentPowertranzGuest = {
  [PAYMENT_METHODS_SERVER.webOnline]: {
    name: PAYMENT_METHODS.powertranzGuest,
    originalName: PAYMENT_METHODS.webOnline,
    isOnline: true,
    hide: false,
    icon: cardIcon
  }
};

const paymentPaypal = {
  [PAYMENT_METHODS_SERVER.paypal]: {
    name: PAYMENT_METHODS.paypal,
    isOnline: true,
    hide: false,
    icon: paypalIcon
  }
};

const paymentApplepay = {
  [PAYMENT_METHODS_SERVER.applepay]: {
    name: PAYMENT_METHODS.applepay,
    isOnline: true,
    hide: false,
    icon: applepayIcon
  }
};

const paymentDirectClickToPay = {
  [PAYMENT_METHODS_SERVER.directClickToPay]: {
    name: PAYMENT_METHODS.directClickToPay,
    isOnline: true,
    hide: false,
    icon: directClickToPayIcon
  }
};

const paymentEdenred = {
  [PAYMENT_METHODS_SERVER.edenred]: {
    name: PAYMENT_METHODS.edenred,
    isOnline: true,
    hide: false,
    icon: edenredIcon
  }
};

const paymentMercadoPago = {
  [PAYMENT_METHODS_SERVER.mercadopago]: {
    name: PAYMENT_METHODS.mercadopago,
    isOnline: true,
    icon: mercadopagoIcon,
    hide: false
  }
};

const paymentSodexo = {
  [PAYMENT_METHODS_SERVER.sodexo]: {
    name: PAYMENT_METHODS.sodexo,
    isOnline: true,
    hide: false,
    icon: sodexoIcon
  }
};

const paymentBizum = {
  [PAYMENT_METHODS_SERVER.bizum]: {
    name: PAYMENT_METHODS.bizum,
    isOnline: true,
    hide: false,
    icon: bizumIcon,
    width: 45
  }
};

const paymentSinpeMovil = {
  [PAYMENT_METHODS_SERVER.sinpeMovil]: {
    name: PAYMENT_METHODS.sinpeMovil,
    isOnline: true,
    hide: false,
    icon: sinpeMovil
  }
};

export const PAYMENT_METHODS_INFO = {
  cl: {
    ...paymentMercadoPago,
    ...paymentCash,
    ...paymentCard,
    ...paymentSinpeMovil,
    [PAYMENT_METHODS_SERVER.webOnline]: {
      name: 'web_online',
      isOnline: true,
      icon: webPayIcon,
      hide: false
    },
    [PAYMENT_METHODS_SERVER.sodexo]: {
      name: PAYMENT_METHODS.sodexo,
      isOnline: true,
      icon: sodexoIcon,
      hide: false
    },
    [PAYMENT_METHODS_SERVER.fpay]: {
      name: PAYMENT_METHODS.fpay,
      isOnline: true,
      icon: fpayIcon,
      hide: false
    },
    [PAYMENT_METHODS_SERVER.tenpo]: {
      name: PAYMENT_METHODS.tenpo,
      isOnline: true,
      icon: tenpoIcon,
      hide: true
    }
  },
  es: {
    ...paymentCash,
    ...paymentCard,
    ...paymentIngenico,
    ...paymentPaypal,
    ...paymentApplepay,
    ...paymentDirectClickToPay,
    ...paymentEdenred,
    ...paymentSodexo,
    ...paymentBizum
  },
  pt: {
    ...paymentCash,
    ...paymentCard,
    ...paymentIngenico,
    ...paymentPaypal,
    ...paymentApplepay,
    ...paymentEdenred,
    ...paymentSodexo,
    ...paymentBizum
  },
  pa: {
    ...paymentCash,
    ...paymentCard,
    ...paymentPowertranz,
    ...paymentPowertranzGuest,
    ...paymentPaypal,
    ...paymentMercantil,
    ...paymentMercantilGuest,
    ...paymentSinpeMovil
  },
  cr: {
    ...paymentCash,
    ...paymentCard,
    ...paymentPowertranz,
    ...paymentPowertranzGuest,
    ...paymentPaypal,
    ...paymentSinpeMovil
  },
  gt: {
    ...paymentCash,
    ...paymentCard,
    ...paymentPowertranz,
    ...paymentPowertranzGuest,
    ...paymentPaypal
  },
  default: { icon: 'icon-payment-debit-card' }
};

export const CARD_PAYMENT_METHODS_INFO = {
  pa: PAYMENT_METHODS.powertranz,
  cr: PAYMENT_METHODS.powertranz,
  gt: PAYMENT_METHODS.powertranz,
  cl: PAYMENT_METHODS.oneclick,
  es: CARD_PAYMENT_METHODS_ONLINE.ingenico,
  pt: CARD_PAYMENT_METHODS_ONLINE.ingenico
};

export const CARD_PAYMENT_MANDATORY_CODE = {
  [PAYMENT_METHODS.powertranz]: true,
  [PAYMENT_METHODS.mercantil]: true,
  [PAYMENT_METHODS.mercantilGuest]: true,
  [PAYMENT_METHODS.oneclick]: false,
  [PAYMENT_METHODS_ONLINE.ingenico]: false
};

export const PAYMENT_CARD_ICONS = {
  Visa: visaIcon,
  Discovery: discoveryIcon,
  MasterCard: masterCardIcon,
  AmericanExpress: americanExpressIcon,
  Unknown: cardIcon,
  RedCompra: redcompraIcon
};

export const PAYMENT_CARD_CREATION_URL = {
  [CARD_PAYMENT_METHODS_ONLINE.powertranz]: '/powertranz/',
  [CARD_PAYMENT_METHODS_ONLINE.mercantil]: '/mercantil/',
  [CARD_PAYMENT_METHODS_ONLINE.oneclick]: '/oneclick/init_inscription'
};
